<template>
    <!-- Personal Profile View -->
    <div class="page-layout">
        <!-- <div class="row" v-if="ajax_call_in_progress">
            <div class="col-lg-12 py-5 px-3 mt-10">
                <div class="flex-container">
                    <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                </div>
            </div>
        </div> -->
        <div class="pb-10">
            <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
                <div class="breadcrumbactive">
                    <div v-if="basicInformation">
                        <span>Profile</span>
                        <div>Basic Information</div>
                    </div>
                    <div v-if="communicationInformation">
                        <span>Profile</span>
                        <a>Communication</a>
                    </div>
                    <div v-if="preferenceInformation">
                        <span>Profile</span>
                        <a>Preferences</a>
                    </div>
                    <div v-if="addressInformation">
                        <span>Profile</span>
                        <a>Address</a>
                    </div>
                    <div v-if="healthInformation">
                        <span>Profile</span>
                        <a>Health</a>
                    </div>
                    <div v-if="remindersInformation">
                        <span>Profile</span>
                        <a>Reminders</a>
                    </div>
                    <div v-if="productAccess">
                        <span>Profile</span>
                        <a>Product Access</a>
                    </div>
                </div>
                <!-- <div class="infoHeader px-3 py-1" style="background: transparent;">
                    <div class="descriptionheader p-0">
                        <div class="description-text-header-left">
                        </div>
                        <div class="description-text-header-right" v-if="!activateEditMode">
                            <a href="javascript:;" class="btn btn-new-primary btn-smm"
                                v-if="loggedInUser.id==$route.params.id" @click="changePassword">Change Password</a>
                        </div>
                        <div class="description-text-header-right" v-if="activateEditMode">
                            <button class="btn btn-new-danger btn-smm ml-3"
                                style="box-shadow: none !important;min-width: 5rem;" @click="hideEdit">CANCEL</button>
                            <button type="button" @click="updateEditUser" class="btn btn-new-green btn-smm ml-3"
                                style="box-shadow: none;"
                                v-if="!requestResponse && !productAccess && !editevent">SAVE</button>
                            <button type="button" class="btn btn-new-green btn-smm ml-3 disabledbutton"
                                style="box-shadow: none;"
                                v-else-if="requestResponse && !productAccess && !editevent">SAVE</button>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="profile-block">
                <div class="profile-block-container pb-3"
                    style="border-radius: 15px;overflow-x: auto; overflow-y: hidden;">
                    <div class="left-sec">
                        <div class=""
                            style="height:100% !important;">
                            <div class="cover-card">
                                <div class="cover-pic row ml-0 mr-0 bg-white">
                                    <img class="cover-card-img-top w-full ml-auto mr-auto"
                                        :src="profile_data.cover_pic || 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/coverpic3.jpg'"
                                        alt="Cover Picture" style="height: 180px; width: fit-content;" />
                                    <div v-if="$route.params.id == loggedInUser.user_id" class="profile-cover-pic-change">
                                        <label for="for_cover_pic"
                                            class="change-cover-pic d-flex align-items-center pointer m-0">
                                            <i class="icon icon-picture mr-1 text-white" style="font-size: 20px;"></i>
                                            Edit Photo
                                        </label>
                                        <input type="file" style="display: none;" id="for_cover_pic" ref="myCoverPic"
                                                accept="image/x-png, image/gif, image/jpeg"
                                                @change="onUserBackground" />
                                        <!-- <div class="change-cover-pic d-flex align-items-center pointer">
                                            <i class="icon icon-picture mr-1 text-white" style="font-size: 20px;"></i>
                                            Edit Photo
                                        </div> -->
                                    </div>
                                </div>
                                <div class="cover-card-block">
                                    <div class="change-pic px-5">
                                        <div style="position: relative;">
                                            <img style="width: 80px; height: 80px; border-radius: 50%;" :src="profile_data.profile_pic || 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'" alt="name" class="profileImg">
                                            <div class="edit-image pointer" v-if="$route.params.id == loggedInUser.user_id">
                                                <label class="profilePicEdit m-0" for="fileprofile" v-if="loggedInUser.id==$route.params.id">
                                                    <img style="border-radius: 50%;" src="/static/images/editgreynew.svg">
                                                </label>
                                                <input type="file" style="display: none;" ref="myProfilePic"
                                                        accept="image/x-png, image/gif, image/jpeg" id="fileprofile"
                                                        @change="onUserProfile" />
                                                <!-- <img style="border-radius: 50%;" src="/static/images/editgreynew.svg"> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="px-4 py-2 mt-10">
                                <div class="pt-4">
                                    <div class="contactInfo">
                                        <div>
                                            <span
                                                class="text-white fw-600 fs-18 contactTitle text-center">{{profile_data.full_name}}</span>
                                            <div class="my-2 contactTitle">
                                                <img src="/static/images/earth-globe.svg" width="20px" height="20px" />
                                                <!-- <span class="text-white fs-14 ml-2">{{profile_data.primary_email.value || "-"}}</span> -->
                                            </div>
                                            <div class="px-6" style="border-top: 2px solid #fff;"></div>
                                            <div class="px-3">
                                                <div class="text-center text-white fw-600 fs-14 pt-3 pb-8">
                                                    <span class="text-capitalize">{{loggedInUser.user_role}} Profile</span>
                                                </div>
                                                <div class="text-center text-white fw-600 fs-14 pt-3 pb-8">
                                                    <img src="/static/images/QR_Code_new.svg" width="100px" height="125px" alt="User Qr Code" />
                                                    <!-- <div>User QR Code</div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="center-section w-100"
                        style="background-color:transparent !important;border:none !important; min-width: 999px !important;">
                        <div class="w-100" style="overflow-x: auto; overflow-y: hidden">
                            <div class="d-flex align-items-center">
                                <div class='slant-tabs d-flex align-items-center justify-content-center text-gray'
                                    :class="{'activeTab':toShow == 'profile','new-blue':toShow == 'profile'}"
                                    @click="changeEditModelView('editBasicInfoView')">
                                    <i class="crmnew-user fs-14 pt-1 mr-1 text-gray" :class="{'defaultDarkBlue':toShow == 'profile'}"></i>
                                    <span class="d-flex" style="width: max-content !important;">Basic Information</span>
                                </div>
                                <div class='slant-tabs d-flex align-items-center justify-content-center text-gray ml-2'
                                    :class="{'activeTab':toShow == 'communication','new-blue':toShow == 'communication'}"
                                    @click="changeEditModelView('editCommunicationView')">
                                    <i class="crmnew-phone fs-14 pt-1 mr-1 text-gray" :class="{'defaultDarkBlue':toShow == 'communication'}"></i>
                                    Communication
                                </div>
                                <div class='slant-tabs d-flex align-items-center justify-content-center text-gray ml-2'
                                    :class="{'activeTab':toShow == 'address','new-blue':toShow == 'address'}"
                                    @click="changeEditModelView('editAddressView')">
                                    <i class="crmnew-marker fs-14 pt-1 mr-1 text-gray" :class="{'defaultDarkBlue':toShow == 'address'}"></i>
                                    Address
                                </div>
                                <div class='slant-tabs d-flex align-items-center justify-content-center text-gray ml-2'
                                    :class="{'activeTab':toShow == 'preference','new-blue':toShow == 'preference'}"
                                    @click="changeEditModelView('editPreferenceView')">
                                    <i class="crmnew-preference fs-14 pt-1 mr-1 text-gray" :class="{'defaultDarkBlue':toShow == 'preference'}"></i>
                                    Preferences
                                </div>
                                <div class='slant-tabs d-flex align-items-center justify-content-center text-gray ml-2'
                                    :class="{'activeTab':toShow == 'health','new-blue':toShow == 'health'}"
                                    @click="changeEditModelView('editHealthView')">
                                    <img :src="toShow == 'health' ? '/static/images/health-icon-new-blue.svg' : 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/health-icon.svg'"
                                        class="activityContact mr-2" style="width: 18px;">
                                    Health
                                </div>
                                <div class='slant-tabs d-flex align-items-center justify-content-center text-gray ml-2'
                                    :class="{'activeTab':toShow == 'reminders','new-blue':toShow == 'reminders'}"
                                    @click="changeEditModelView('editRemindersView')">
                                        <img 
                                        :src="toShow == 'reminders' ? '/static/images/reminders-icon-new-blue.svg' : 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/reminders-icon.svg'"
                                        class="activityContact mr-2" style="width: 18px;">
                                    Reminders
                                </div>
                                <!-- <div class='slant-tabs d-flex align-items-center justify-content-center text-gray ml-2'
                                    :class="{'activeTab':toShow == 'products','new-blue':toShow == 'products'}"
                                    @click="changeEditModelView('editProductAccess')">
                                    <img 
                                        :src="toShow == 'products' ? 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images//product-access-icon-default-blue.svg' : 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images//product-access-icon.svg'"
                                        class="activityContact mr-1" style="width: 18px;">
                                    Products Access
                                </div> -->
                            </div>
                        </div>
                        <div class="center-content">
                            <div class="preview-elements">
                                <div class="tab-content profile-tab-content scroll-media" id="myTabContent"
                                    style="max-height: 49em;overflow-y: auto;overflow-x: hidden;">
                                    <div class="" id="basictab" aria-labelledby="basic-tab" v-if="basicInformation">
                                        <personal-info-view>
                                        </personal-info-view>
                                    </div>
                                    <div class="" id="communicationtab" aria-labelledby="communication-tab"
                                        v-else-if="communicationInformation">
                                        <communication-view>
                                        </communication-view>
                                    </div>
                                    <div class="" id="addresstab" aria-labelledby="address-tab"
                                        v-else-if="addressInformation">
                                        <address-view>
                                        </address-view>
                                    </div>
                                    <div class="" id="addresstab" aria-labelledby="address-tab"
                                        v-else-if="preferenceInformation">
                                        <preference-view>
                                        </preference-view>
                                    </div>
                                    <div class="" id="addresstab" aria-labelledby="address-tab"
                                        v-else-if="healthInformation">
                                        <health-view></health-view>
                                    </div>
                                    <div class="" id="addresstab" aria-labelledby="address-tab"
                                        v-else-if="remindersInformation">
                                        <reminders-view
                                            >
                                        </reminders-view>
                                    </div>
                                    <!-- <div class="" id="producttab" aria-labelledby="product-tab"
                                        v-else-if="productAccess">
                                        <product-access-view></product-access-view>
                                    </div>  -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <following modal_name="following_modal" :follower="follow.by" :following="follow.to"></following> -->
        <accept-two-step-verification v-if="accept_two_step_verification" @hideTwoStepVerification="hideTwoStepVerification" modal_name="two-step-verification"></accept-two-step-verification>
    </div>
</template>
<script>
    import profile from '../mixins/profile';
    import connection from '../mixins/connection';
    import { User } from '../mixins/user';
    import { EventBus } from '../eventBus/nav-bar-event';
    const PersonalInfoView = () => import('./PersonalInfoView');
    const CommunicationView = () => import('./CommunicationView');
    const AddressView = () => import('./AddressView');
    const PreferenceView = () => import('./PreferenceView');
    const HealthView = () => import('./HealthView');
    const RemindersView = () => import('./RemindersView');
    const AcceptTwoStepVerification = () => import('./AcceptTwoStepVerification');
    // import ProductAccessView from "../ProductAccessView";
    import {HalfCircleSpinner} from 'epic-spinners';
import axios from 'axios';

    export default {
        data() {
            return {
                userProfile: "https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/coverpic3.jpg",
                requestResponse: false,
                basicInformation: true,
                healthInformation: false,
                remindersInformation: false,
                communicationInformation: false,
                addressInformation: false,
                preferenceInformation: false,
                productAccess: false,
                toShow: "profile",
                profile_data: {},
                follow: {
                    by: "",
                    to: "",
                },
                connectionStatus: {
                    connection_status: null,
                },
                employeeData: [],
                sendInvite: false,
                connectionCount: "",
                requestType: "",
                empStatus: "",
                requestResponse: false,
                employe_id: '',
                company_id: '',
                ajax_call_in_progress: false,
                accept_two_step_verification: false
            };
        },
        mixins: [User, connection, profile],

        components: {
            PersonalInfoView,
            CommunicationView,
            AddressView,
            PreferenceView,
            HealthView,
            RemindersView,
            HalfCircleSpinner,
            AcceptTwoStepVerification
            // ProductAccessView,
        },
        methods: {
            hideTwoStepVerification() {
                this.accept_two_step_verification = false;
            },
            async checkTwoFactorVerification() {
                try {
                    let params = {
                    login_id: this.loggedInUser.login_id
                    }
                    let response = await this.checkLogin(params);
                    if(response.status_id == 1 && response.is_two_step_verification == false) {
                    this.accept_two_step_verification = true;
                    setTimeout(() => {
                        this.$modal.show("two-step-verification");
                    }, 500);
                    }
                }
                catch(err) {
                    // console.log(err);
                }
            },
            async sendRequest(note) {
                let params = {
                    connection_status: "requested",
                    message: note ? note : "",
                    sent_to: this.profile_data._id,
                };
                let res = await this.$http.post("/connections/add", params);
                if (res.data.status_id == 1) {
                    // this.$modal.show("send_request_modal");
                    // setTimeout(() => {
                    //     this.$modal.hide("send_request_modal");
                    // }, 1000);
                    // window.location.reload();
                    this.getConnectionStatus({
                        user_id: this.$route.params.id,
                        request_type: this.requestType,
                    });
                }
            },
            async followUser(data) {
                let res = await this.$http.post("/following/add", {
                    sent_to: data._id,
                });
                if (res.data.status_id == 1) {
                    this.follow.by = this.loggedInUser.full_name;
                    this.follow.to = data.full_name;
                    this.$modal.show("following_modal");
                    setTimeout(() => {
                        this.$modal.hide("following_modal");
                    }, 1500);
                    this.getConnectionStatus({
                        user_id: this.$route.params.id,
                    });
                }
            },
            async unfollowUser(id) {
                this.getConnectionStatus({
                    user_id: this.$route.params.id,
                });
                let res = await this.$http.get("/following/unfollow?id=" + this.connectionStatus.following_id);
                if (res.data.status_id == 1) {
                    this.getConnectionStatus({
                        user_id: this.$route.params.id,
                    });
                }
            },
            // Cover Picture Change Function
            onUserBackground(e) {
                var input = event.target;
                if (input.files && input.files[0]) {
                    const CancelToken = axios.CancelToken;
                    const source = CancelToken.source();
                    let attachment = {
                        name: input.files[0].name,
                        size: JSON.stringify(input.files[0].size),
                        source: source,
                        progress: 0,
                        created_time: new Date(),
                        error: "",
                    };
                    this.getResumableURLprofile(input.files[0], attachment, "cover_pic");
                }
            },
            // User Profile Image Change Function
            onUserProfile(e) {
                var input = event.target;
                if (input.files && input.files[0]) {
                    const CancelToken = axios.CancelToken;
                    const source = CancelToken.source();
                    let attachment = {
                        name: input.files[0].name,
                        size: JSON.stringify(input.files[0].size),
                        source: source,
                        progress: 0,
                        created_time: new Date(),
                        error: "",
                    };
                    this.getResumableURLprofile(input.files[0], attachment, "profile_pic");
                }
            },
            getResumableURLprofile(file, attachment, type) {
                let data = {
                    file: attachment.name,
                };
                data = new FormData();
                data.append("file", file);
                this.$http
                    .post("https://cloudapis.digicollect.com/external/upload_file", data)
                    .then(response => {
                        if (response.data.status_id == 1) {
                            this.updatePersonalData(response.data.url, type);
                        }
                    })
                    .catch(error => {
                        // this.error = "Error Uploading"
                    });
            },
            async updatePersonalData(data, type) {
                try {
                    let param = {};
                    if(type == 'profile_pic') {
                        param.profile_pic = data;
                    } else if(type == 'cover_pic') {
                        param.cover_pic = data;
                    }
                    let response = await this.updatePersonalInfo(param, this.loggedInUser.user_id);
                    if(response.status_id == 1) {
                        this.personalInfoData(this.$route.params.id);
                        EventBus.$emit('personal_info_data_update');
                    }
                }
                catch(err) {

                }
            },
            changeEditModelView(editName) {
                if (editName == "editBasicInfoView") {
                    this.toShow = 'profile';
                    this.basicInformation = true;
                    this.communicationInformation = false;
                    this.addressInformation = false;
                    this.preferenceInformation = false;
                    this.healthInformation = false;
                    this.remindersInformation = false;
                    this.productAccess = false;
                } else if (editName == "editCommunicationView") {
                    this.toShow = 'communication';
                    this.basicInformation = false;
                    this.communicationInformation = true;
                    this.addressInformation = false;
                    this.preferenceInformation = false;
                    this.healthInformation = false;
                    this.remindersInformation = false;
                    this.productAccess = false;
                } else if (editName == "editAddressView") {
                    this.toShow = 'address';
                    this.basicInformation = false;
                    this.communicationInformation = false;
                    this.addressInformation = true;
                    this.preferenceInformation = false;
                    this.healthInformation = false;
                    this.remindersInformation = false;
                    this.productAccess = false;
                } else if (editName == "editPreferenceView") {
                    this.toShow = 'preference';
                    this.basicInformation = false;
                    this.communicationInformation = false;
                    this.addressInformation = false;
                    this.preferenceInformation = true;
                    this.healthInformation = false;
                    this.remindersInformation = false;
                    this.productAccess = false;
                } else if (editName == "editHealthView") {
                    this.toShow = 'health';
                    this.basicInformation = false;
                    this.communicationInformation = false;
                    this.addressInformation = false;
                    this.preferenceInformation = false;
                    this.healthInformation = true;
                    this.remindersInformation = false;
                    this.productAccess = false;
                } else if (editName == "editRemindersView") {
                    this.toShow = 'reminders';
                    this.basicInformation = false;
                    this.communicationInformation = false;
                    this.addressInformation = false;
                    this.preferenceInformation = false;
                    this.healthInformation = false;
                    this.remindersInformation = true;
                    this.productAccess = false;
                } else if (editName == "editProductAccess") {
                    this.toShow = 'products';
                    this.basicInformation = false;
                    this.communicationInformation = false;
                    this.addressInformation = false;
                    this.preferenceInformation = false;
                    this.healthInformation = false;
                    this.remindersInformation = false;
                    this.productAccess = true;
                }
            },
            updateTab(data) {
                if (data == 'profile') {
                    this.basicInformation = true;
                    this.communicationInformation = false;
                    this.addressInformation = false;
                    this.preferenceInformation = false;
                    this.healthInformation = false;
                    this.remindersInformation = false;
                    this.productAccess = false;
                } else if (data == 'communication') {
                    this.basicInformation = false;
                    this.communicationInformation = true;
                    this.addressInformation = false;
                    this.preferenceInformation = false;
                    this.healthInformation = false;
                    this.remindersInformation = false;
                    this.productAccess = false;
                } else if (data == 'address') {
                    this.basicInformation = false;
                    this.communicationInformation = false;
                    this.addressInformation = true;
                    this.preferenceInformation = false;
                    this.healthInformation = false;
                    this.remindersInformation = false;
                    this.productAccess = false;
                } else if (data == 'preference') {
                    this.basicInformation = false;
                    this.communicationInformation = false;
                    this.addressInformation = false;
                    this.preferenceInformation = true;
                    this.healthInformation = false;
                    this.remindersInformation = false;
                    this.productAccess = false;
                } else if (data == 'health') {
                    this.basicInformation = false;
                    this.communicationInformation = false;
                    this.addressInformation = false;
                    this.preferenceInformation = false;
                    this.healthInformation = true;
                    this.remindersInformation = false;
                    this.productAccess = false;
                } else if (data == 'reminders') {
                    this.basicInformation = false;
                    this.communicationInformation = false;
                    this.addressInformation = false;
                    this.preferenceInformation = false;
                    this.healthInformation = false;
                    this.remindersInformation = true;
                    this.productAccess = false;
                } else if (data == 'products') {
                    this.basicInformation = false;
                    this.communicationInformation = false;
                    this.addressInformation = false;
                    this.preferenceInformation = false;
                    this.healthInformation = false;
                    this.remindersInformation = false;
                    this.productAccess = true;
                }
            },
            // async getConnectionsCount(id) {
            //     let res = await this.$http.get("/connections/connection_count?id=" + id);
            //     if (res.data.status_id == 1) {
            //         this.connectionCount = res.data.response;
            //     }
            // },
            // getRequestType() {
            //     if (this.account) {
            //         this.requestType = "business";
            //     } else {
            //         this.requestType = "personal";
            //     }
            // },
            async personalInfoData(id) {
                try {
                    let param = id;
                    let response = await this.getPersonalInfo(param);
                    if (response.status_id == 1) {
                        this.profile_data = response.response;
                    }
                }
                catch(err) {
                    // console.log(err);
                }
            },
        },
        created() {
            if(this.$cookie.get("Token")) {
                // this.getRequestType();
                if (this.$route.params.id) {
                    this.personalInfoData(this.$route.params.id);
                    // this.getConnectionsCount(this.$route.params.id);
                } else {
                    // this.$router.push("/PersonalInfoView/" + this.loggedInUser.id);
                    this.personalInfoData(this.loggedInUser.id);
                    // this.getConnectionsCount(this.loggedInUser.id);
                }
                // this.getConnectionStatus({
                //     user_id: this.$route.params.id,
                //     request_type: this.requestType,
                // });
            } else {
                this.$router.push("/login");
            }
        },
        // beforeRouteEnter(to, from, next) {
        //     if(from.path == "/login") this.checkTwoFactorVerification();
        //     next()
        // },
        mounted() {
            setTimeout(() => {
                EventBus.$emit("personal_info_data_update");
            }, 500);
            // setTimeout(() => {
            //     if(this.loggedInUser.first_login === true) {
            //         this.checkTwoFactorVerification();
            //     }
            // }, 1000);
            setTimeout(() => {
                if (this.$route.params.id) {
                    this.personalInfoData(this.$route.params.id);
                    // this.getConnectionsCount(this.$route.params.id);
                } else {
                    this.$router.push("/PersonalInfoView/" + this.loggedInUser.id);
                    // this.personalInfoData(this.loggedInUser.id);
                    // this.getConnectionsCount(this.loggedInUser.id);
                }
            }, 500);
            EventBus.$on("toShow", data => {
                this.updateTab(data)
                // if (data == 'products') {
                //     this.is_product_access_edit = false;
                // } else {
                //     this.is_product_access_edit = true;
                // }
                this.toShow = data;
            });
            EventBus.$on("personal_info_data_update", () => {
                setTimeout(async () => {
                    if (this.$route.params.id) {
                        this.personalInfoData(this.$route.params.id);
                    } else {
                        this.personalInfoData(this.loggedInUser.id);
                    }
                }, 500);
            });
            this.employe_id = this.loggedInUser.user_id;
            this.company_id = this.loggedInUser.company_details ? this.loggedInUser.company_details._id : '';
        },

        // Adding Store Permissions
        computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
            account() {
                return this.$store.getters.account;
            },
            selectedCompany() {
                return this.$store.getters.selectedCompany;
            },
        },
        watch: {
            "$route.params": function (path) {
                if (path.id) {
                    this.personalInfoData(this.$route.params.id);
                    // this.getConnectionsCount(this.$route.params.id);
                } else {
                    this.$router.push("/PersonalInfoView/" + this.loggedInUser.id);
                    // this.personalInfoData(this.loggedInUser.id);
                    // this.getConnectionsCount(this.loggedInUser.id);
                }
            },
        },
        beforeRouteUpdate(to, from, next) {
            this.toShow = 'profile';
            this.basicInformation = true;
            this.communicationInformation = false;
            this.addressInformation = false;
            this.preferenceInformation = false;
            const id = to.params.id;
            // this.personalInfoData(id);
            // this.getConnectionsCount(id);
            // this.getRequestType();
            // this.getConnectionStatus({
            //     user_id: id,
            //     request_type: this.requestType,
            // });
            // this.getConnectionsCount();
            next();
        },
        destroyed() {
            EventBus.$off('toShow');
            EventBus.$off('personal_info_data_update');
        }
    };
</script>

<style scoped>

.profile-block-container{
    /* min-width:65em; */
    overflow-x: auto;
}

.center-content{
    min-height:535px;
}
</style>